import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'idle',
    loadChildren: () => import('./idle/idle.module').then( m => m.IdlePageModule)
  },
  {
    path: 'modal-category',
    loadChildren: () => import('./modal-category/modal-category.module').then( m => m.ModalCategoryPageModule)
  },
  {
    path: 'modal-personaldata',
    loadChildren: () => import('./modal-personaldata/modal-personaldata.module').then( m => m.ModalPersonaldataPageModule)
  },
  {
    path: 'modal-ticket',
    loadChildren: () => import('./modal-ticket/modal-ticket.module').then( m => m.ModalTicketPageModule)
  },
  {
    path: 'modal-photo',
    loadChildren: () => import('./modal-photo/modal-photo.module').then( m => m.ModalPhotoPageModule)
  },
  {
    path: 'display',
    loadChildren: () => import('./display/display.module').then( m => m.DisplayPageModule)
  },
  {
    path: 'modal-cs',
    loadChildren: () => import('./modal-cs/modal-cs.module').then( m => m.ModalCsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
